<template>
  <div class="wrapper--error404">
    <div class="error404">
      <div class="error404__logo">
        <img src="@/assets/logoBig.svg" />
      </div>
      <div class="error404__main">
        <h2 class="error404__title">404</h2>
        <div class="error404__text">
          <p>Упс...</p>
          <p>Что-то пошло не так</p>
        </div>
        <div class="error404__text--bottom">Страница не найдена</div>
      </div>
    </div>
    <div class="wrapper--error404__btn">
      <Button large @click="$router.push({ name: 'Events' })">
        На главную
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Blocks/Button";
export default {
  name: "404",
  components: { Button },
};
</script>

<style lang="scss">
.wrapper--error404 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 128px;
  align-items: center;
  justify-content: center;
}
.error404 {
  display: flex;
  align-items: center;
  gap: 32px;
  &__logo {
  }
  &__main {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  &__title {
    font-size: 5rem;
    line-height: 6.2rem;
    color: var(--red);
  }
  &__text {
    font-size: 5rem;
    line-height: 6.2rem;
    font-weight: 500;
    &--bottom {
      font-size: 2.2rem;
      line-height: 2.6rem;
    }
  }
}
@include adaptive(tablet-big) {
  .wrapper--error404 {
    gap: 60px;
  }
  .error404 {
    flex-direction: column;
    &__main {
      text-align: center;
    }
    &__logo {
      width: 200px;
    }
  }
}
@include adaptive(phone) {
  .wrapper--error404 {
    display: block;
    margin-top: 80px;
    &__btn {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
  }
  .error404 {
    gap: 20px;
    &__logo {
      width: 100px;
    }
    &__text {
      font-size: 2.2rem;
      line-height: 2.7rem;
      font-weight: 500;
      &--bottom {
        font-size: 1.8rem;
        line-height: 2.2rem;
      }
    }
  }
}
</style>
